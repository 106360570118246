<template lang="pug">
  v-card(class="mt-0 custom-card-transactions px-5 py-5").text-center
    h2.custom-card-transactions__title TOTAL BALANCE
    h2.custom-card-transactions__balance.my-5 {{earnings | moneyFormat}}
    .custom-card-transactions__buttons
      v-btn.mr-0(outlined color="white" @click="$emit('withdraw')") Withdraw
</template>

<script>
  const formatter = new Intl.NumberFormat('es-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })
  export default {
    filters: {
      moneyFormat: function (value) {
        return formatter.format(value)
      },
    },

    computed: {
      earnings () {
        return this.$store.getters['withdraw/getEarnings']
      },
    },
  }
</script>

<style lang="scss" scoped>
    .custom-card-transactions{
        background-color: var(--font-color-2);
        border-radius: 17px !important;
        display: flex;
        flex-direction: column;
        &__title,
        &__balance{
            color: white;
        }
        &__title{
            font-size: 18pt;
        }
        &__balance{
            font-size: 25pt;
            font-weight: bold;
        }
    }
</style>
